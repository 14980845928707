// @ts-check
import { Controller } from "stimulus"
import Choices from "choices.js"
import Cleave from "cleave.js"
import Skyflow from "skyflow-js"
import * as Sentry from "@sentry/browser"

export default class extends Controller {
  static targets = [
    "entityType",
    "select",
    "phone",
    "secure",
    "taxTypeSelect",
    "taxTypeInput",
    "statusSelect",
    "bankName",
    "bankStreet",
    "bankCity",
    "bankState",
    "bankZip",
    "bankAccountName",
    "bankAccountNumber",
    "bankABA",
    "bankReference",
    "taxIdInput",
    "stateSelect",
    "disregardedEntityFieldset",
  ]

  initialize() {
    this.taxIdCleaves = []

    if (this.skyflowEnabled) {
      this.skyflowClient = this.initSkyflowClient()
    }

    const statuses = JSON.parse(this.element.dataset.statuses)
    this.statusChoices = Object.keys(statuses).map((v) => {
      return { value: v, label: statuses[v] }
    })

    this.choicesOptions = {
      searchEnabled: false,
      itemSelectText: "",
      allowHTML: true,
      shouldSort: false,
    }

    this.initForm()

    this.updateBankFieldStates()

    // clear error states on change
    $(this.element).on("choice", (e) => {
      this.clearFieldError(e.target)
    })
    $(this.element).on("keyup change", "input.form-control", (e) => {
      this.clearFieldError(e.target)
    })

    // pull Skyflow data and populate if this is a revision
    if (this.element.dataset.skyflowId && this.skyflowEnabled) {
      this.skyflowClient
        .getById({
          records: [
            {
              ids: [this.element.dataset.skyflowId],
              table: "entities",
              redaction: Skyflow.RedactionType.PLAIN_TEXT,
            },
          ],
        })
        .then((response) => {
          this.secureTargets.forEach((el) => {
            const fieldName = el.getAttribute("name")
            const value = response.records[0].fields[fieldName] || ""

            if (el.tagName == "SELECT") {
              this.bankStateSelect.setChoiceByValue(value)
            } else {
              el.value = value
            }
          })
        })
        .catch(() => {
          Sentry.captureMessage(
            "Skflow failed to fetch secure data for onboarding revision",
          )
        })
    }
  }

  initForm() {
    this.entityTypeSelect = new Choices(
      this.entityTypeTarget,
      this.choicesOptions,
    )
    this.entityTypeTarget.addEventListener("change", (e) => {
      $(this.element).removeClass("unfilled")
      this.updateEntityState()
    })

    this.statusSelect = new Choices(
      this.statusSelectTarget,
      this.choicesOptions,
    )

    this.bankStateSelect = new Choices(this.bankStateTarget, {
      ...this.choicesOptions,
      searchEnabled: true,
    })

    this.selectTargets.forEach((el) => {
      new Choices(el, this.choicesOptions)
    })

    this.stateSelectTargets.forEach((el) => {
      new Choices(el, { ...this.choicesOptions, searchEnabled: true })
    })

    this.phoneTargets.forEach((field) => {
      new Cleave(field, {
        numericOnly: true,
        blocks: [0, 3, 3, 4],
        delimiters: ["(", ") ", "-"],
      })
    })

    const $taxTypeSelect = $(this.taxTypeSelectTarget)
    const $taxTypeInput = $(this.taxTypeInputTarget)

    $taxTypeSelect.on("change", () => {
      if ($taxTypeSelect.val() == "other") {
        $taxTypeInput.val("")
        $taxTypeInput.show()
      } else {
        $taxTypeInput.val($taxTypeSelect.val())
        $taxTypeInput.hide()
      }
    })

    this.updateEntityState()
  }

  initSkyflowClient() {
    return Skyflow.init({
      vaultID: SKYFLOW_VAULT_ID, // ID of the vault that the client should connect to.
      vaultURL: SKYFLOW_VAULT_URL, // URL of the vault that the client should connect to.
      getBearerToken: () => {
        return new Promise((resolve, reject) => {
          $.ajax({
            url: this.element.dataset.tokenUrl,
            type: "GET",
            success: function (data) {
              resolve(data)
            },
            error: function (error) {
              Sentry.captureMessage(
                "Skflow failed to initialize client for onboarding",
              )
              reject(error)
            },
          })
        })
      },
      options: {
        logLevel:
          RAILS_ENV === "development"
            ? Skyflow.LogLevel.DEBUG
            : Skyflow.LogLevel.ERROR,
      },
    })
  }

  get skyflowEnabled() {
    return (
      typeof SKYFLOW_VAULT_ID !== "undefined" &&
      typeof SKYFLOW_VAULT_URL !== "undefined"
    )
  }

  get isEntity() {
    return (
      [
        "irrevocable_trust",
        "corporation",
        "partnership",
        "llc",
        "foundation",
        "endowment",
      ].indexOf(this.entityTypeTarget.value) >= 0
    )
  }

  get isTenants() {
    return (
      ["tenants_in_common", "joint_tenants"].indexOf(
        this.entityTypeTarget.value,
      ) >= 0
    )
  }

  get isDisregardedEntity() {
    return (
      ["corporation", "llc", "revocable_trust", "irrevocable_trust"].indexOf(
        this.entityTypeTarget.value,
      ) >= 0
    )
  }

  toggleFieldset(fieldset, show) {
    fieldset.classList.toggle("d-none", !show)
    fieldset.disabled = !show
  }

  updateEntityState() {
    const entity = this.isEntity

    $(this.element)
      .toggleClass("entity", this.isEntity)
      .toggleClass("individual", this.entityTypeTarget.value == "individual")
      .toggleClass("tenants", this.isTenants)

    this.toggleFieldset(
      this.disregardedEntityFieldsetTarget,
      this.isDisregardedEntity,
    )

    this.statusSelect.setChoices(
      this.statusChoices.filter((choice) => {
        return (
          (["family_5m", "entity_25m", "entity_owners"].indexOf(choice.value) ==
            -1) ^
          entity
        )
      }),
      "value",
      "label",
      true,
    )

    this.taxIdCleaves.forEach((c) => c.destroy())
    this.taxIdCleaves = []

    this.taxIdCleaves.push(
      new Cleave(this.taxIdInputTarget, {
        blocks: entity ? [2, 7] : [3, 2, 4],
        delimiter: "-",
        numericOnly: true,
      }),
    )
  }

  clearFieldError(el) {
    const $field = $(el).closest(".form-group, td")
    $field.removeClass("error").find(".b-onboarding-field-error").remove()
    $field.find(".field_with_errors").addClass("cleared")
    $field.find("input").removeClass("error")
  }

  updateBankFieldStates() {
    if ($("[name=bank_prefill]:checked").val() == "tbd") {
      $(this.element).find(".banking").hide()

      this.bankNameTarget.value =
        this.bankStreetTarget.value =
        this.bankCityTarget.value =
        this.bankZipTarget.value =
        this.bankAccountNameTarget.value =
        this.bankAccountNumberTarget.value =
        this.bankABATarget.value =
          "tbd"
      this.bankReferenceTarget.value = "tbd"
    } else {
      const disabled = $("[name=bank_prefill]:checked").val() != "other"

      $(this.element).find(".banking").show()

      this.bankNameTarget.disabled =
        this.bankStreetTarget.disabled =
        this.bankCityTarget.disabled =
        this.bankZipTarget.disabled =
        this.bankAccountNameTarget.disabled =
        this.bankAccountNumberTarget.disabled =
        this.bankABATarget.disabled =
          disabled
      if (disabled) {
        this.bankStateSelect.disable()
        this.bankReferenceTarget.placeholder =
          "Enter entity account information..."
      } else {
        this.bankStateSelect.enable()
        this.bankReferenceTarget.placeholder = ""
      }
    }
  }

  secureFormData(form) {
    return form
      .find(
        "input:not([data-onboarding-onboarding-target~=secure]), select:not([data-onboarding-onboarding-target~=secure])",
      )
      .filter(":not([name=bank_prefill])")
      .fieldSerialize()
  }

  onSubmit(e) {
    e.preventDefault()

    const $form = $(this.element).find("form")
    let secureDataMissing = false

    const bankTBD = $("[name=bank_prefill]:checked").val() == "tbd"
    const bankPrefilled =
      !bankTBD && $("[name=bank_prefill]:checked").val() != "other"
    const collectTaxID =
      this.isEntity || this.entityTypeTarget.value == "revocable_trust"

    const isFieldRequired = (fieldName) => {
      let fieldRequired = true
      if (
        ((fieldName.startsWith("bank") || fieldName == "aba_number") &&
          bankTBD) ||
        (fieldName == "bank_reference" && !bankPrefilled) ||
        (fieldName == "tax_id" && !collectTaxID)
      ) {
        fieldRequired = false
      }
      return fieldRequired
    }

    // store and clear the secure data
    const secureData = Object.fromEntries(
      this.secureTargets
        .map((el) => {
          const $el = $(el)
          const value = $el.val().trim()
          const fieldName = $el.attr("name")

          if (isFieldRequired(fieldName) && (!value || value.length == 0)) {
            secureDataMissing = true
          }

          return [fieldName, value]
        })
        .filter((a) => Boolean(a[1])),
    )

    const bankPrefill = $form.find("[name=bank_prefill]:checked").val()

    const fieldErrorHTML =
      '<div class="b-onboarding-field-error">This field is required.</div>'

    const repopulateSecureData = () => {
      this.secureTargets.forEach((el) => {
        const $el = $(el)
        const fieldName = $el.attr("name")
        const value = secureData[fieldName]
        if (value) {
          if ($el.is("select")) {
            // bit of a hack here since there is only one
            this.bankStateSelect.setChoiceByValue(value)
          } else {
            $el.val(value)
          }
        } else if (isFieldRequired(fieldName)) {
          $el.closest(".form-group").addClass("error").append(fieldErrorHTML)
        }
      })

      $(this.element)
        .find(`[name=bank_prefill][value=${bankPrefill}]`)
        .prop("checked", true)
      this.updateBankFieldStates()
    }

    $.post(
      this.element.dataset.validateUrl,
      this.secureFormData($form),
      (data, textStatus, jqXHR) => {
        if (data || secureDataMissing) {
          // form is not valid
          if (data) {
            $form.replaceWith(data)
            this.initForm()
          } else {
            $form.find("[type=submit]").prop("disabled", false)
          }
          repopulateSecureData()
        } else {
          const completeFormSubmit = () => {
            repopulateSecureData()

            $.ajax({
              url: $form.attr("action"),
              method: "POST",
              data: this.secureFormData($form),
              error: () => {
                alert(
                  "There was an error saving your data.  Please contact an administrator.",
                )
              },
            })
          }

          if (this.skyflowEnabled) {
            if (!bankTBD) {
              secureData.bank_csz = `${secureData.bank_city}, ${secureData.bank_state}, ${secureData.bank_zip}`
            }

            const records = {
              records: [
                {
                  table: "entities",
                  fields: secureData,
                },
              ],
            }

            this.skyflowClient
              .insert(records)
              .then((response) => {
                this.secureTargets.forEach((el) => {
                  const fieldName = el.getAttribute("name")
                  const value = response.records[0].fields[fieldName]
                  if (value) {
                    $form.append(
                      `<input type="hidden" name="investor_entity[${fieldName}]" value="${value}" />`,
                    )
                  }
                })
                if (!bankTBD) {
                  $form.append(
                    `<input type="hidden" name="investor_entity[bank_csz]" value="${response.records[0].fields["bank_csz"]}" />`,
                  )
                }
                $form.append(
                  `<input type="hidden" name="investor_entity[skyflow_id]" value="${response.records[0].fields["skyflow_id"]}" />`,
                )
                completeFormSubmit()
              })
              .catch((err) => {
                alert(
                  "There was an error saving your data.  Please contact an administrator.",
                )
              })
          } else {
            Sentry.captureMessage(
              "Skflow not initialized. Onboarding unable to persist secure data.",
            )
            completeFormSubmit()
          }
        }
      },
    )
  }

  onInvestRadioChange(e) {
    $(this.element)
      .find(".security-field")
      .toggle(e.currentTarget.value == "yes")
  }

  onBankPrefillChange(e) {
    this.bankNameTarget.value = e.currentTarget.dataset.name || ""
    this.bankStreetTarget.value = e.currentTarget.dataset.street || ""
    this.bankCityTarget.value = e.currentTarget.dataset.city || ""
    this.bankStateSelect.setChoiceByValue(e.currentTarget.dataset.state || "")
    this.bankZipTarget.value = e.currentTarget.dataset.zip || ""
    this.bankAccountNameTarget.value = e.currentTarget.dataset.accountName || ""
    this.bankAccountNumberTarget.value =
      e.currentTarget.dataset.accountNumber || ""
    this.bankABATarget.value = e.currentTarget.dataset.abaNumber || ""
    this.bankReferenceTarget.value = e.currentTarget.dataset.bankReference || ""

    this.clearFieldError(this.bankNameTarget)
    this.clearFieldError(this.bankStreetTarget)
    this.clearFieldError(this.bankCityTarget)
    this.clearFieldError(this.bankStateTarget)
    this.clearFieldError(this.bankZipTarget)
    this.clearFieldError(this.bankAccountNameTarget)
    this.clearFieldError(this.bankAccountNumberTarget)
    this.clearFieldError(this.bankABATarget)
    this.clearFieldError(this.bankReferenceTarget)

    this.updateBankFieldStates()

    return true
  }
}
